import { Component, OnInit, ViewChild } from '@angular/core';
import { RolePrivilegeEnum } from 'app/data/common/models/transient/role.enums';
import { NgOption, NgOptionComponent, NgSelectComponent } from '@ng-select/ng-select';
import { CookieService } from 'ngx-cookie-service';
import { ViewDirective } from 'app/views/view';
import { Injector } from '@angular/core';
import { ToastrService, Toast } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { EmergencyService } from 'app/data/common/modelservices/emergency.service';
import AuthenticationService from 'app/data/app/services/authentication.service';
import { ControlCenter } from 'app/data/common/models';
import { ControlCenterService } from 'app/data/modelservices';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html'
})
export class AppHeaderComponent extends ViewDirective implements OnInit {
    @ViewChild('dropdown') dropdown: NgSelectComponent;

    public controlCenters: Array<NgOption> = new Array();
    public username = '';
    public environment = environment;
    public selectedControlCenter;
    public clientVersion: String = "3.11.1";
    public serverVersion: String = "3.11.0";
    public controlCenter = '';

    private controlCenterMap: Map<string, ControlCenter>;
    constructor(injector: Injector, private controlCenterSerivce: ControlCenterService,
        public authenticationService: AuthenticationService, private cookieService: CookieService,
        private emergencyService: EmergencyService, private toasterService: ToastrService, public router: Router) {
        super(injector)
    }

    ngOnInit() {
        if (this.authenticationService.hasPrivilege(RolePrivilegeEnum.su_CCSwitch)) {
            this.controlCenterSerivce.get().then(controlCenterArray => {
                this.controlCenters = new Array();
                this.controlCenterMap = new Map();
                controlCenterArray.forEach((controlCenter) => {
                    this.controlCenters.push({ label: controlCenter.name, value: controlCenter.id })
                    this.controlCenterMap.set(controlCenter.id, controlCenter);
                })
                const option = (this.cookieService.get(RolePrivilegeEnum.su_CCSwitch) !== undefined) ? this.cookieService.get(RolePrivilegeEnum.su_CCSwitch) : this.authenticationService.getAuthenticatedUser().controlCenterRelation.id;
                this.selectedControlCenter = this.controlCenters.filter((item)=>{
                    return item.value == option;
                })[0];
            })
        }

        if (this.authenticationService.isAuthenticated()) {
            if (this.authenticationService.getAuthenticatedUser().controlCenterRelation != null) {
                this.controlCenter = this.authenticationService.getAuthenticatedUser().controlCenterRelation.name;
            }
            
            this.username = this.authenticationService.getAuthenticatedUser().username;
            if (this.authenticationService.hasPrivilege(RolePrivilegeEnum.route_emergencyDetail)) {
                this.emergencyService.subActiveEmergencies().then(sub => {
                    this.addSub(sub);
                    sub.onNext((next) => {
                        if (next.action === 'create' || next.action === 'enter') {
                            //this.toasterService.info('Neuer Notfall in Ihrer Nähe', 'Neuer Notfall');
                            this.toasterService.info(
                                // '<a class="btn btn-sm btn-primary" href="#/app/emergency/active/' + next.objectId + '"><i class="fa fa-ambulance"></i> Notfall anzeigen</a> <a class="btn btn-sm btn-primary pull-right"><i class="fa fa-close"></i> Ausblenden</a>',
                                'Zum Öffnen des Notfalls mit der ID: ' + next.objectId + ' hier klicken.',
                                'Neuer Notfall', 
                                {
                                    closeButton: false,
                                    timeOut: 0,
                                    enableHtml: true,
                                    extendedTimeOut: 2000,
                                }
                            ).onTap.subscribe((action) => window.location.href="#/app/emergency/active/" + next.objectId);
                            
                        }
                    })
                })
            }
        } else {
            window.location.href="#/pages/login"
        }

    }

    public releaseNotes() {
        window.location.href="#/app/changelog"
    }

    public async logout() {
        await this.authenticationService.logout();
        window.location.href="#/pages/login"
    }

    public controlCenterSelected(option: NgOption) {
        if(option) {
            this.cookieService.set(RolePrivilegeEnum.su_CCSwitch, option.value);
        } else {
            this.cookieService.set(RolePrivilegeEnum.su_CCSwitch, undefined);
        }
        
        window.location.reload();
    }
}
